import { useState, useEffect, useCallback } from "react";
import {
  signInO365,
  requestStorageAccess,
  LoginContextInternal,
  tokenStorageKey,
  getOfficeAccessTokenLegacy,
} from "./loginContext";
import { acquireToken as naaAcquireToken } from "./naaAuth.ts";
import { Box, Button, Typography } from "@mui/material";
import { MainLogo } from "../components/MainLogo";
import { useLocales } from "../hooks";
import imgUrl from "../assets/main-logo.png";
import { getItem } from "../utils/localStorage.ts";

export function LoginProvider({ children }: { children: React.ReactNode }) {
  // get the query client
  const { translate } = useLocales();

  const [accessToken, setAccessToken] = useState<string | null | undefined>(
    undefined
  );
  const [idToken, setIdToken] = useState<string | undefined>(undefined);
  const [needStorageAccess, setNeedStorageAccess] = useState(false);
  const [storageAccessBlocked, setStorageAccessBlocked] = useState(false);
  const [identityAPIFailed, setIdentityAPIFailedState] = useState(false);
  const [naaFailed, setNaaFailedState] = useState(false);

  const markIdentityAPIFailed = useCallback(() => {
    setIdentityAPIFailedState(true);
    setAccessToken(null);
    setIdToken(undefined);
  }, [setIdentityAPIFailedState]);

  useEffect(() => {
    console.log(`Token from local storage: ${getItem(tokenStorageKey)}`);
    console.log(`Token from state: ${accessToken}`);
  });
  const setStorageAccessState = useCallback(
    (value: boolean, blocked: boolean) => {
      setNeedStorageAccess(value);
      setStorageAccessBlocked(blocked);
    },
    [setNeedStorageAccess, setStorageAccessBlocked]
  );

  useEffect(() => {
    if (document.hasStorageAccess) {
      document.hasStorageAccess().then((hasAccess) => {
        if (hasAccess) {
          return;
        }
        setNeedStorageAccess(true);
      });
    }
  }, []);

  const setTokenInternal = useCallback(
    (token: string, accessToken?: string | null) => {
      console.log("ACCESS token obtained in login flow ", accessToken);
      setAccessToken(accessToken); // force rerender
      setIdToken(token);
    },
    [setAccessToken]
  );
  const setUserName = useCallback((userName: string) => {
    console.log(userName);
  }, []);
  const displayError = useCallback((error: string) => {
    console.log(error);
  }, []);

  const login = useCallback(async () => {
    if (!accessToken) {
      console.log("sign in o365");
      // Identity API is currently the best but might fail in some browsers with some security options
      if (
        Office.context.requirements.isSetSupported("IdentityAPI", "1.3") &&
        !identityAPIFailed
      ) {
        getOfficeAccessTokenLegacy(setTokenInternal, setIdentityAPIFailedState);
      } else if (
        Office.context.requirements.isSetSupported("NestedAppAuth", "1.1") &&
        !naaFailed
      ) {
        console.log("NestedAppAuth is supported");
        naaAcquireToken(setTokenInternal, setNaaFailedState);
      } else {
        signInO365(setTokenInternal, setUserName, displayError);
      }
    }
  }, [
    accessToken,
    setTokenInternal,
    setUserName,
    displayError,
    identityAPIFailed,
    setIdentityAPIFailedState,
    naaFailed,
    setNaaFailedState,
  ]);

  useEffect(() => {
    if (!accessToken) {
      login();
    }
  }, [accessToken, login]);

  if (!accessToken && identityAPIFailed && naaFailed) {
    return (
      <Box
        width="100%"
        pb="20px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography textAlign="center" sx={{ mb: "30px" }}>
          {translate("login.description")}
        </Typography>
        <Button variant="contained" onClick={login}>
          {translate("login.button")}
        </Button>
      </Box>
    );
  }

  if (needStorageAccess && !storageAccessBlocked && !accessToken) {
    return (
      <>
        <MainLogo src={imgUrl} alt="AironWorks logo" />
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {translate("mainScreen.storageDescription")}
        </Typography>
        <Button
          variant="contained"
          sx={{ mb: "16px", mt: "16px" }}
          onClick={() => requestStorageAccess(setStorageAccessState)}
        >
          {translate("mainScreen.grantAccess")}
        </Button>
      </>
    );
  }

  return (
    <LoginContextInternal.Provider
      value={{
        login,
        idToken: idToken,
        accessToken: accessToken,
        markIdentityAPIFailed: markIdentityAPIFailed,
      }}
    >
      {children}
    </LoginContextInternal.Provider>
  );
}
